<template>
  <div>
    <v-card
      v-if="showDataUsage"
      class="pa-8 data_usage_card"
    >
      <div>
        <div class="d-flex align-center">
          <img
            src="../../../assets/icons/datausageicon.png"
            alt=""
            class="mr-2"
          >
          <h4 class="data_usage_title">
            Data usage
          </h4>
        </div>
        <div class="d-flex align-center justify-space-between data_count">
          <div>
            <h1 v-if="showDataUsage">
              {{ dataUsageStatistics.monthly_data_usage | dataConverter }}
            </h1>
            <p>This Month</p>
          </div>
          <div>
            <h1 v-if="showDataUsage">
              {{ dataUsageStatistics.total_data_usage | dataConverter }}
            </h1>
            <p>Total</p>
          </div>
        </div>
      </div>
    </v-card>
    <v-card
      v-else
      class="pa-8 data_usage_card"
    >
      <div>
        <div class="d-flex align-center">
          <img
            src="../../../assets/icons/datausageicon.png"
            alt=""
            class="mr-2"
          >
          <h4 class="data_usage_title">
            Data usage
          </h4>
        </div>
        <div class="d-flex align-center justify-space-between data_count">
          <div>
            <h1>__</h1>
            <p>This Month</p>
          </div>
          <div>
            <h1>__</h1>
            <p>Total</p>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment-timezone';
export default {
  name: 'DataUsageNewDashboard',
  filters: {
    dataConverter (data) {
      return Number(data).toFixed(2);
    },
  },
  data () {
    return {};
  },
  computed: {
    dataUsageStatistics () {
      return this.$store.getters['dashboard/getDataUsageStatistics'];
    },
  },
  mounted () {
    this.getDataUsageStatistics();
  },
  methods: {
    convertToGmt (date) {
      return moment.utc(date).format();
    },
    showDataUsage () {
      if (Object.keys(this.dataUsageStatistics).length > 0) {
        return true;
      }
      return false;
    },
    async getDataUsageStatistics () {
      const startOfMonth = moment().startOf('month');
      const currentDateTime = moment();
      await this.$store.dispatch('dashboard/fetchDataUsageStatistics', {
        params: {
          from: this.convertToGmt(startOfMonth),
          to: this.convertToGmt(currentDateTime),
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.data_usage_card{
    background: #EAF7F1;
    border: 2px solid #97D2B6;
    box-shadow: none !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 10px;
}
.data_usage_title{
    font-size: 20px;
}
.data_count{
    width: 50%;
    margin-top: 12px;
}
</style>
