<template>
  <v-card class="pa-8 dashboard_cards">
    <div class="d-flex align-center">
      <img
        src="../../../assets/icons/leastactiveusericon.png"
        alt=""
        width="22px"
        class="mr-2"
      >
      <h4>Least Active Users</h4>
    </div>
    <div class="dashboard_card_count">
      <h1>{{ leastActiveAppUsers.length.toString() }}</h1>
      <p>{{ lastUpdatedAt | diffHumans }}</p>
    </div>
  </v-card>
</template>
<script>
import moment from 'moment';
export default {
    name: 'DashboardLeastActiveUsers',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    computed: {
        leastActiveAppUsers () {
        return this.$store.getters['dashboard/getLeastActiveAppUsers'];
      },
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
    },
    mounted () {
        this.getLeastActiveAppUsers();
        this.lastUpdated();
    },
    methods: {
        async getLeastActiveAppUsers () {
        await this.$store.dispatch('dashboard/fetchLeastActiveAppUsers');
      },
      lastUpdated () {
        this.$store.dispatch('dashboard/lastUpdatedAt', {
          updatedAt: moment().toISOString(),
        });
      },
    },
};
</script>
<style lang="scss" scoped>
    .dashboard_card_count{
    position: absolute;
    bottom: 10px;
}
.dashboard_cards h4 {
    font-size: 22px;
    font-weight: 400;
    position: relative;
}
</style>
