<template>
  <base-bar-chart
    v-if="showChart"
    :chart-data="dataCollection"
    :options="options"
    style="max-height: 250px;"
  />
</template>
  <script>
    import moment from 'moment';
    import { isCurrentCheckInsDataExist } from 'src/helpers/chart-data-helper';

    export default {
      name: 'CurrentCheckIns',
      filters: {
        diffHumans (val) {
          return moment(val).fromNow();
        },
        convertToLocal (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
        },
      },
      data: () => ({
        label: 'Active Check-Ins',
        currentDateTime: moment(),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontColor: '#000',
              fontsize: 14,
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: '#000',
                stepSize: 50,
              },
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'My Sites',
                fontColor: '#000',
              },
              ticks: {
                display: false,
                fontColor: '#000',
              },
            }],
          },
        },
      }),
      computed: {
        chartData () {
          return this.$store.getters['dashboard/getCurrentActiveCheckIns'];
        },
        dataExist () {
          return this.chartData.length > 0;
        },
        showChart () {
          return Object.keys(this.dataCollection).length > 0;
        },
        dataCollection () {
          if (this.dataExist === false) {
            return {};
          }
          return this.generateDataCollection();
        },
        noDataExist () {
          const dataExist = isCurrentCheckInsDataExist(this.chartData);
          if (dataExist === true) {
            return false;
          }
          return true;
        },
        lastUpdatedAt () {
          return this.$store.getters['dashboard/getLastUpdatedAt'];
        },
        suspiciousActivitiesDaily () {
          return this.$store.getters['dashboard/getCurrentSuspiciousActivities'];
        },
      },
      mounted () {
        this.fetchCurrentActiveCheckInsData();
        this.fetchCurrentSuspiciousActivitiesData();
      },
      methods: {
        fetchCurrentActiveCheckInsData () {
          this.$store.dispatch('dashboard/fetchCurrentActiveCheckInsData');
        },
        fetchCurrentSuspiciousActivitiesData () {
          this.$store.dispatch('dashboard/fetchCurrentSuspiciousActivities', {
            params: {
              from: moment().startOf('day').toISOString(),
              to: moment().toISOString(),
            },
          });
        },
        generateDataCollection () {
          const actionCount = [];
          const labels = [];
          for (const result of this.chartData) {
            actionCount.push(result.active_users.length);
            labels.push(result.project.name);
          }
          return {
            labels: labels,
            datasets: [
              {
                data: actionCount,
                label: this.label,
                backgroundColor: '#674ADD',
                fill: true,
                barThickness: 40,
                borderRadius: 10,
              },
            ],
          };
        },
      },
    };
  </script>
  <style scoped>
  .alert-style {
    color: #C62828;
    text-align: center;
  }
  .my-card-title-style {
    color: #37474F;
    margin-left: 2px;
    margin-top: 10px;
    font-size: 18px;
  }
  .my-card-subtitle-style {
    color: #37474F;
    margin-left: 2px;
    margin-top: 7px;
    font-size: 14px;
  }
  .my-updation-text-style {
    color: #37474F;
    font-size: 12px;
  }
  </style>
