<template>
  <v-card class="pa-8 dashboard_cards">
    <div class="d-flex align-center">
      <img
        src="../../../assets/icons/suscheckinicon.png"
        alt=""
        width="22px"
        class="mr-2"
      >
      <h4>Suspicious Check-Ins</h4>
    </div>
    <div class="dashboard_card_count">
      <h1>{{ suspiciousCheckIns.length.toString() }}</h1>
      <p>{{ lastUpdatedAt | diffHumans }}</p>
    </div>
  </v-card>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DashboardSusCheckinCount',
  filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
  computed: {
    suspiciousCheckIns () {
        return this.$store.getters['widgets/getSuspiciousCheckIns'];
      },
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
  },
  mounted () {
    this.getAllSuspiciousCheckIns();
    this.lastUpdated();
  },
  methods: {
    lastUpdated () {
        this.$store.dispatch('dashboard/lastUpdatedAt', {
          updatedAt: moment().toISOString(),
        });
      },
    async getAllSuspiciousCheckIns () {
        await this.$store.dispatch('widgets/fetchSuspiciousCheckIns');
      },
  },
};
</script>
<style scoped>
.dashboard_card_count{
    position: absolute;
    bottom: 10px;
}
.dashboard_cards h4 {
    font-size: 22px;
    font-weight: 400;
    position: relative;
}
</style>
