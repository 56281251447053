<template>
  <div>
    <base-pie-chart
      v-if="showChart"
      :chart-data="dataCollection"
      :options="options"
      style="max-height: 250px;"
    />
  </div>
</template>
  <script>
    import moment from 'moment';
    import { isProjectDataExist } from 'src/helpers/chart-data-helper';

    export default {
      name: 'LongestProjectDelays',
      filters: {
        diffHumans (val) {
          return moment(val).fromNow();
        },
        convertToLocal (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
        },
      },
      data: () => ({
        title: 'Project Delay Hours',
        currentDateTime: moment(),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'left',
            maxHeight: '10px',
            align: 'left',
            display: true,
            textAlign: 'left',
            text: 'Longest Completion Delays',
            labels: {
              fontColor: '#263238',
            },
          },
        },
      }),
      computed: {
        chartData () {
          return this.$store.getters['dashboard/getLongestProjectDelays'];
        },
        dataExist () {
          return this.chartData.length > 0;
        },
        showChart () {
          return Object.keys(this.dataCollection).length > 0;
        },
        dataCollection () {
          if (this.dataExist === false) {
            return {};
          }
          return this.generateDataCollection();
        },
        noDataExist () {
          const dataExist = isProjectDataExist(this.chartData);
          if (dataExist === true) {
            return false;
          }
          return true;
        },
        lastUpdatedAt () {
          return this.$store.getters['dashboard/getLastUpdatedAt'];
        },
      },
      mounted () {
        this.fetchLongestProjectDelays();
      },
      methods: {
        fetchLongestProjectDelays () {
          this.$store.dispatch('dashboard/fetchLongestProjectDelays');
        },
        generateDataCollection () {
          const actionCount = [];
          const labels = [];
          const itemColor = ['#F55F98', '#7471F5', '#CB71F5', '#A071F5', '#F571F2'];
          for (const result of this.chartData) {
            actionCount.push(result.delayed_days);
            labels.push(result.project.name);
          }
          return {
            labels: labels,
            datasets: [
              {
                data: actionCount,
                backgroundColor: itemColor,
                borderColor: '#fff',
                fill: false,
                hoverOffset: 4,
              },
            ],
          };
        },
      },
    };
  </script>
  <style scoped>
  .alert-style {
    color: #C62828;
    text-align: center;
  }
  .my-card-title-style {
    color: #37474F;
    margin-left: 7px;
    margin-top: 10px;
    font-size: 18px;
  }
  .my-card-subtitle-style {
    color: #37474F;
    margin-left: 7px;
    margin-top: 7px;
    font-size: 14px;
  }
  .my-updation-text-style {
    color: #37474F;
    font-size: 12px;
  }
  </style>
